import React, { useRef } from "react"
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";

// Configs
import multiContentSlider from "../../utilities/multiContentSlider";

// Styles
import './Layout01.scss';

// Components
import SectionWrapper from "../../components/SectionWrapper";
import SectionHeader from "../../components/SectionHeader";
import NavigationArrow from "../../components/NavigationArrow";
import SingleContentCard from "../../components/SingleContentCard";
import SingleAreaCard from "../../components/SingleAreaCard";

const Layout01 = ( props ) => {

    const {
        className,
        id,
        sectionHeaderData,
        DescriptionContentList = [],
        SliderData = [],
        CardsData = [],
        layout,
        responsiveShowHide,
        cardType = 'contentCard',
    } = props;

    const sliderRef = useRef();

    const settings = {
        ...multiContentSlider,
        nextArrow: (
            <NavigationArrow
                type="left"
                sliderRef={ sliderRef }
            />
        ),
        prevArrow: (
            <NavigationArrow
                type="right"
                sliderRef={ sliderRef }
            />
        ),
    };

    const CardComponent = getCardComponent( cardType );

    return (
        <SectionWrapper
            className={ `layout-01-wrapper ${ className || '' }` }
            id={ id || '' }
        >
            <Container>
                <Row>
                    <Col>
                        <div className="layout-01-header-section">
                            <SectionHeader
                                sectionHeaderData={ sectionHeaderData }
                            />
                        </div>
                    </Col>
                </Row>
                { DescriptionContentList.length > 0 && (
                    <Row>
                        <Col lg="10">
                            <div className="layout-01-content-section">
                                { DescriptionContentList.map( ( descriptionContentItem, descriptionContentIndex ) => {
                                    return (
                                        <p
                                            key={ descriptionContentIndex }
                                            className={ DescriptionContentList.length - 1 === descriptionContentIndex ? "mb-0" : "" }
                                        >
                                            { descriptionContentItem }
                                        </p>
                                    )
                                } ) }
                            </div>
                        </Col>
                    </Row>
                ) }
                { SliderData.length > 0 && (
                    <div className="multi-content-slider-wrapper">
                        <Slider
                            { ...settings }
                            ref={ sliderRef }
                        >
                            { SliderData.map( ( sliderDataItem, sliderDataIndex ) => {
                                return (
                                    <div
                                        key={ sliderDataIndex }
                                        className="slider-image-wrapper"
                                    >
                                        <CardComponent
                                            { ...sliderDataItem }
                                        />
                                    </div>
                                )
                            } ) }
                        </Slider>
                    </div>
                ) }
                { CardsData.length > 0 && (
                    <Row className="multi-content-cards-wrapper">
                        {
                            CardsData.map( ( cardsDataItem, cardsDataIndex ) => {

                                const getClassName = ( cardsDataIndex ) => {
                                    if ( cardsDataIndex > 0 && cardsDataIndex < 2 ) {
                                        return 'hide-on-tab-and-mobile';
                                    } else if ( cardsDataIndex > 0 ) {
                                        return 'hide-on-mobile';
                                    }
                                    return '';
                                }

                                return (
                                    <Col
                                        key={ cardsDataIndex }
                                        { ...layout }
                                        className={ responsiveShowHide && getClassName( cardsDataIndex ) }
                                    >
                                        <SingleAreaCard
                                            { ...cardsDataItem }
                                        />
                                    </Col>
                                )
                            } )
                        }
                    </Row>
                ) }
            </Container>
        </SectionWrapper>
    )
}

export default Layout01

const getCardComponent = ( cardType ) => {
    if ( cardType === 'areaCard' ) {
        return SingleAreaCard;
    }
    return SingleContentCard;
}