import React, { useRef } from "react"
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";

// Configs
import multiContentSlider from "../../../utilities/multiContentSlider";

// Styles
import './ThingsToDo.scss';

// LayoutHelpers
import Layout01 from "../../../layout-helpers/Layout01";

const ThingsToDo = ( { thingsToDoData } ) => {

    const { Title, ...otherProperties } = thingsToDoData;

    const sectionHeaderData = {
        Title: Title
    };

    return (
        <Layout01
            className="things-to-do-wrapper"
            id="thingstodo"
            sectionHeaderData={ sectionHeaderData }
            cardType="contentCard"
            { ...otherProperties }
        />
    )
}

export default ThingsToDo
