import React, { useState } from "react";
// import $ from 'jquery'

// Hookds
import UseLoadExternalScript from "customHooks/useLoadExternalScript";

const markerImageSrc = "/images/marker.png";
const locRatingScriptsJs = 'https://www.locrating.com/scripts/locratingIntegrationScripts.js';

const LocratingMap = ( props ) => {

    let [ activeCTA ] = useState( 'location' );

    let _data = props.data || "";

    let _mapClass = 'single'; // only one marker map
    if ( _data.length && _data.length > 1 ) { // more than one markers map
        _mapClass = 'all';
    }

    let _markerImage = markerImageSrc;

    let _height = 'auto'
    let _width = 'auto'

    const _iframeId = 'location-map';

    const _iframeTitle = "Location Map";

    const renderMap = ( _activeCTA ) => {
        if ( _activeCTA ) {
            activeCTA = _activeCTA;
        }

        let _type = '';
        switch ( activeCTA ) {
            case 'location':
            default:
                _type = 'transport';
                break;
            case 'schools':
                _type = 'schools';
                break;
            case 'local':
                _type = 'localinfo';
                break;
        }

        if ( typeof window !== 'undefined' ) {
            const _baseUrl = window.location.origin;
            if ( _markerImage.toLowerCase().indexOf( _baseUrl ) === -1 ) {
                _markerImage = _baseUrl + _markerImage;
            }
            if ( typeof window.loadLocratingPlugin !== 'undefined' ) {
                try { // try
                    let _firstLat = '';
                    let _firstLng = '';
                    if ( _data ) {
                        if ( _data[ 0 ] ) {
                            if ( _data[ 0 ].lat ) {
                                _firstLat = _data[ 0 ].lat;
                            }
                            if ( _data[ 0 ].lng ) {
                                _firstLng = _data[ 0 ].lng;
                            }
                        }
                        let _firstMarkerImage = _markerImage;
                        if ( 'all' === _mapClass ) {
                            _firstMarkerImage = '.';
                        }
                        if ( 'single' === _mapClass ) {
                            _type = 'all';
                        }
                        window.loadLocratingPlugin( {
                            id: _iframeId,
                            lat: _firstLat,
                            lng: _firstLng,
                            starttab: props.mapview ? props.mapview : 'location',
                            type: _type,
                            icon: _firstMarkerImage,
                            zoom: 11,
                            hidestationswhenzoomedout: true,
                            hidestationslist: true,
                            onLoaded: function () {
                                let _n = 1;
                                for ( let i in _data ) {
                                    let _item = _data[ i ];
                                    let _markerId = _item.id;
                                    let _lat = _item.lat;
                                    let _lng = _item.lng;
                                    let _html = _item.html;
                                    if ( typeof window.addLocratingMapMarker !== 'undefined' ) {
                                        window.addLocratingMapMarker( _iframeId,
                                            {
                                                id: _markerId,
                                                lat: _lat,
                                                lng: _lng,
                                                html: _html,
                                                icon: _markerImage,
                                                iconHeight: _height,
                                                iconWidth: _width,
                                                zoom: 11,
                                                onClick: function ( marker ) {
                                                    // $( '.area-guide-box' ).removeClass( 'area-guide-active' );
                                                    // var target = $( "#" + marker.id );
                                                    // if ( target.length ) {
                                                    //     $( 'html,body' ).animate( {
                                                    //         scrollTop: target.offset().top - 250
                                                    //     }, 100 );

                                                    //     $( target ).addClass( 'area-guide-active' );
                                                    //     return false;
                                                    // }
                                                }
                                            } );
                                    }
                                    _n++;
                                }
                            }
                        } );
                    }
                } catch ( err ) {
                    console.log( err );
                }
            }
        }
    }

    return (
        <div id="map-holder" className={ _mapClass || '' }>
            <UseLoadExternalScript
                src={ locRatingScriptsJs }
                defer="true"
                loadScript={ renderMap }
            />
            <iframe
                id={ _iframeId }
                title={ _iframeTitle }
                width="100%"
                height="100%"
            >
            </iframe>
        </div>
    );
};

export default LocratingMap;