import React from "react"
import { Container, Row, Col } from "react-bootstrap";

// Components
import SectionWrapper from "components/SectionWrapper";
import CustomTextField from "components/CustomTextField";
import CustomDropDown from "components/CustomDropdown";

// Styles
import './OffMarketProperties.scss';
import CustomButton from "components/CustomButton";
import CustomCheckbox from "components/CustomCheckbox";

const propertySector = [
    {
        display: 'Residential',
        value: 'Residential'
    },
    {
        display: 'Commercial',
        value: 'Commercial'
    }
];

const area = [
    {
        display: 'London',
        value: 'London'
    },
    {
        display: 'Manchester',
        value: 'Manchester'
    }
];

const toletType = [
    {
        display: 'Residential',
        value: 'Residential'
    },
    {
        display: 'Commercial',
        value: 'Commercial'
    }
];

const propertyType = [
    {
        display: 'Apartment',
        value: 'Apartment'
    },
    {
        display: 'Independent House',
        value: 'Independent House'
    }
];

const preferredDate = [
    {
        display: '04-12-2021',
        value: '04-12-2021'
    },
    {
        display: '05-12-2021',
        value: '05-12-2021'
    }
];

const preferredTime = [
    {
        display: '03:00 PM',
        value: '03:00 PM'
    },
    {
        display: '04:00 PM',
        value: '04:00 PM'
    }
];

const bedroomOptions = [
    {
        display: '1 bed +',
        value: '1'
    },
    {
        display: '2 bed +',
        value: '2'
    },
    {
        display: '3 bed +',
        value: '3'
    },
    {
        display: '4 bed +',
        value: '4'
    },
];

const termsOptions = [
    'I agree to the Terms & Conditions and Privacy & Data Protection Policy'
];

const newsletterOptions = [
    'I agree to receive newsletters and company updates as per the Privacy Policy.'
];

const layoutConfig = {
    md: {
        span: 6
    },
    sm: {
        span: 12
    }
};

const OffMarketProperties = ( { offMarketPropertiesData } ) => {
    return (
        <SectionWrapper className="off-market-properties-wrapper">
            <Container>
                <div className="off-market-properties-header">
                    <h2 className="general-heading">
                        Tell us about off market properties
                    </h2>
                </div>
                <div className="off-market-properties-body">
                    <div className="form-wrapper">
                        <div className="form-fields">
                            <Row>
                                <Col
                                    { ...layoutConfig }
                                >
                                    <CustomTextField
                                        label="YOUR NAME"
                                        defaultValue="Christopher Rowe"
                                    />
                                </Col>
                                <Col
                                    { ...layoutConfig }
                                >
                                    <CustomTextField
                                        label="EMAIL ADDRESS"
                                        defaultValue="Christopher@starberry.tv"
                                    />
                                </Col>
                                <Col
                                    { ...layoutConfig }
                                >
                                    <CustomTextField
                                        label="TELEPHONE NUMBER"
                                        defaultValue="07891219348"
                                    />
                                </Col>
                                <Col
                                    { ...layoutConfig }
                                >
                                    <CustomDropDown
                                        name="propertySector"
                                        label="PROPERTY SECTOR"
                                        defaultOption={ propertySector[ 0 ] }
                                        options={ propertySector }
                                        variant="black-caret"
                                    />
                                </Col>
                                <Col
                                    { ...layoutConfig }
                                >
                                    <CustomDropDown
                                        name="area"
                                        label="AREA"
                                        defaultOption={ area[ 0 ] }
                                        options={ area }
                                        variant="black-caret"
                                    />
                                </Col>
                                <Col
                                    { ...layoutConfig }
                                >
                                    <CustomDropDown
                                        name="tolet"
                                        label="TO LET / FOR SALE"
                                        defaultOption={ toletType[ 0 ] }
                                        options={ toletType }
                                        variant="black-caret"
                                    />
                                </Col>
                                <Col
                                    { ...layoutConfig }
                                >
                                    <CustomDropDown
                                        name="propertyType"
                                        label="TYPE OF PROPERTY"
                                        defaultOption={ propertyType[ 0 ] }
                                        options={ propertyType }
                                        variant="black-caret"
                                    />
                                </Col>
                                <Col
                                    { ...layoutConfig }
                                    lg={ {
                                        span: 6,
                                        order: 0
                                    } }
                                    md={ {
                                        span: 12,
                                        order: 1
                                    } }
                                    xs={ {
                                        span: 12,
                                        order: 1
                                    } }
                                >
                                    <Row>
                                        <Col md="6" xs="12">
                                            <CustomDropDown
                                                name="preferredDate"
                                                label="PREFERRED DATE"
                                                defaultOption={ preferredDate[ 0 ] }
                                                options={ preferredDate }
                                                variant="black-caret"
                                            />
                                        </Col>
                                        <Col>
                                            <CustomDropDown
                                                name="preferredTime"
                                                label="PREFERRED TIME"
                                                defaultOption={ preferredTime[ 0 ] }
                                                options={ preferredTime }
                                                variant="black-caret"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col
                                    { ...layoutConfig }
                                    lg={ {
                                        span: 6,
                                        order: 1
                                    } }
                                    md={ {
                                        span: 6,
                                        order: 0
                                    } }
                                    xs={ {
                                        span: 12,
                                        order: 0
                                    } }
                                >
                                    <CustomDropDown
                                        name="bedrooms"
                                        label="BEDROOMS"
                                        defaultOption={ bedroomOptions[ 0 ] }
                                        options={ bedroomOptions }
                                        variant="black-caret"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-terms show-only-on-tab-and-mobile">
                            <CustomCheckbox
                                name="termsAndConditions"
                                options={ termsOptions }
                                defaultOption={ termsOptions[ 0 ] }
                            />
                            <CustomCheckbox
                                name="newsletterSignUp"
                                options={ newsletterOptions }
                                defaultOption={ newsletterOptions[ 0 ] }
                            />
                        </div>
                        <div>
                            <CustomButton
                                variant="green"
                                value="SUBMIT"
                            />
                        </div>
                    </div>
                </div>
            </Container>
        </SectionWrapper>
    )
}

export default OffMarketProperties
