import React, { useRef } from "react"
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";

// Configs
import multiContentSlider from "../../../utilities/multiContentSlider";

// Styles
import './Transportation.scss';

// LayoutHelpers
import Layout01 from "../../../layout-helpers/Layout01";

const Transportation = ( { transportationData } ) => {

    const { Title, ...otherProperties } = transportationData;

    const sectionHeaderData = {
        Title: Title
    };

    return (
        <Layout01
            className="transportation-wrapper"
            id="transportation"
            sectionHeaderData={ sectionHeaderData }
            cardType="contentCard"
            { ...otherProperties }
        />
    )
}

export default Transportation
