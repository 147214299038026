import * as React from 'react';
import { Container } from "react-bootstrap";

// Components
import SectionWrapper from "../../../components/SectionWrapper";
import SectionHeader from "../../../components/SectionHeader";
import PropertyCards from "../../../components/PropertyCards";

// Styles
import './FeaturedProperties.scss';

const FeaturedProperties = ( { featuredPropertiesData } ) => {

    const sectionHeaderData = {
        Title: featuredPropertiesData.Title,
        SubTitle: featuredPropertiesData.SubTitle,
        Link: {
            FullText: 'View All Properties',
            ShortText: 'View All',
            Href: '#'
        }
    }

    return (
        <SectionWrapper className="featured-properties-wrapper" id="featuredProperties">
            <Container>
                <div className='property-cards-header-section'>
                    <SectionHeader
                        sectionHeaderData={ sectionHeaderData }
                    />
                </div>
                <div className="sub-section-wrapper property-cards-body-section">
                    <PropertyCards
                        propertyCardsData={ featuredPropertiesData.Properties }
                        layout={ {
                            lg: 4,
                            sm: 6,
                            xs: 12
                        } }
                        responsiveShowHide
                    />
                </div>
            </Container>
        </SectionWrapper>
    );
};

export default FeaturedProperties;
