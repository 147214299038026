import * as React from "react"

import Seo from "../components/seo"

// Temporary Data
import commonData from "../data/common-data.json"
import discoverMarylebone from "../data/discover-marylebone.json"

// Components
import Header from "../components/Header"
import PageBanner from "../components/PageBanner"
import BreadCrumbs from "../components/BreadCrumbs"
import ScrollTabs from "../components/ScrollTabs"
import About from "../layout/DiscoverMarylebone/About"
import FeaturedProperties from "../layout/DiscoverMarylebone/FeaturedProperties"
import FoodAndDrink from "../layout/DiscoverMarylebone/FoodAndDrink"
import Shop from "../layout/DiscoverMarylebone/Shop"
import ThingsToDo from "../layout/DiscoverMarylebone/ThingsToDo"
import Transportation from "../layout/DiscoverMarylebone/Transportation"
import MapLocation from "../layout/DiscoverMarylebone/MapLocation"
import AreaGuides from "../layout/DiscoverMarylebone/AreaGuides"
import DirectorProfile from "layout/DiscoverMarylebone/DirectorProfile"
import OffMarketProperties from "layout/DiscoverMarylebone/OffMarketProperties"
import PropertyTypes from "layout/DiscoverMarylebone/PropertyTypes"
import InteriorDesign from "layout/DiscoverMarylebone/InteriorDesign"
import CharityStories from "layout/DiscoverMarylebone/CharityStories"
import CharitiesWeSupport from "layout/DiscoverMarylebone/CharitiesWeSupport"
import Footer from "../components/Footer"

const breadCrumbsData = [
    {
        name: 'Home',
        link: '#'
    },
    {
        name: 'Explore London',
        link: '#'
    },
    {
        name: 'Discover Marylebone',
        link: '#'
    }
];

const headerTabs = [
    {
        name: 'About',
        id: '#about'
    },
    {
        name: 'Property',
        id: '#featuredProperties'
    },
    {
        name: 'Food & Drink',
        id: '#foodanddrink'
    },
    {
        name: 'Shop',
        id: '#shop'
    },
    {
        name: 'Things To Do',
        id: '#thingstodo'
    },
    {
        name: 'Transportation',
        id: '#transportation'
    },
    {
        name: 'Location',
        id: '#location'
    },
];

const DiscoverMarylebone = () => {
    return (
        <>
            <Seo title="Discover Marylebone" />
            <div className="page-wrapper">
                <Header headerData={ commonData } />
                <PageBanner pageBannerData={ discoverMarylebone.BannerData } />
                <BreadCrumbs
                    breadCrumbsData={ breadCrumbsData }
                />
                <ScrollTabs
                    headerTabs={ headerTabs }
                >
                    <About />
                    <FeaturedProperties
                        featuredPropertiesData={ discoverMarylebone.FeaturedProperties }
                    />
                    <FoodAndDrink
                        foodAndDrinkData={ discoverMarylebone.FoodAndDrink }
                    />
                    <Shop
                        shopData={ discoverMarylebone.Shop }
                    />
                    <ThingsToDo
                        thingsToDoData={ discoverMarylebone.ThingsToDo }
                    />
                    <Transportation
                        transportationData={ discoverMarylebone.Transportation }
                    />
                    <MapLocation />
                    {/* <AreaGuides
                        areaGuidesData={ discoverMarylebone.AreaGuides }
                    /> */}
                    {/* <DirectorProfile /> */}
                    <OffMarketProperties />
                    <PropertyTypes />
                    <InteriorDesign />
                    <CharityStories />
                    {/* <CharitiesWeSupport /> */}
                </ScrollTabs>
                <Footer
                    footerData={ commonData.FooterMenu }
                    footerTermsMenu={ commonData.FooterTermsMenu }
                    footerSocialLinks={ commonData.FooterSocialLinks }
                    footerCompanies={ commonData.FooterCompanies }
                />
            </div>
        </>
    )
}

export default DiscoverMarylebone
