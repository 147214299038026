import React from "react"
import LocratingMap from "../../../components/LocratingMap";

// Styles
import './MapLocation.scss';

const MapLocation = ( { mapLocationData } ) => {

    let mapItems = [];
    let mapItem = {};
    mapItem[ 'lat' ] = 51.456913;
    mapItem[ 'lng' ] = -0.073099;
    mapItem[ 'id' ] = 'map-holder';
    mapItems.push( mapItem );;

    return (
        <div
            className="map-location-wrapper"
            id="location"
        >
            <LocratingMap
                data={ mapItems }
            />
        </div>
    )
}

export default MapLocation
