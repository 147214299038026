import * as React from "react"
import { Row, Col, Card } from "react-bootstrap"

// Styles
import "./SingleContentCard.scss"

const SingleContentCard = ( singleContentCardData = {} ) => {

    const { Title, SubTitle, ImageSource } = singleContentCardData;

    return (
        <div className="single-content-card-wrapper">
            <Card>
                <div className="card-container-image">
                    <Card.Img
                        variant="top"
                        src={ require( `../../images/restaurant-slider/${ ImageSource }` ).default }
                        alt={ Title || 'card-image' }
                    />
                </div>
                <Card.Body>
                    <Card.Title>
                        { Title }
                    </Card.Title>
                    <Card.Text>
                        { SubTitle }
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}

export default SingleContentCard
