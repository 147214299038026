import SectionWrapper from "components/SectionWrapper";
import React from "react"
import { Col, Container, Row } from "react-bootstrap";

// Styles
import './PropertyTypes.scss';

const propertyTypes = [
    {
        name: 'Residential Sales',
        imageSource: 'residential-sales.png'
    },
    {
        name: 'Residential Lettings',
        imageSource: 'residential-lettings.png'
    },
    {
        name: 'Commercial Sales',
        imageSource: 'commercial-sales.png'
    },
    {
        name: 'Commercial Lettings',
        imageSource: 'commercial-lettings.png'
    },
    {
        name: 'Short Lets',
        imageSource: 'short-lets.png'
    }
];

const PropertyTypes = ( { propertyTypesData } ) => {
    return (
        <SectionWrapper className="property-types-wrapper">
            <Container>
                <Row>
                    <Col lg="10">
                        <p className="highlight-text">
                            At Resi. Land, we are proud to make your experience smooth, stress-free and enjoyable.
                        </p>
                        <p className="mb-0">
                            Resi.Land Rent, Sell and Buy Prime Central London residential and commercial property.
                            We are a responsible Landlord with the widest selection of rental properties in the Capital, all managed in-house by a dedicated team.
                            We are always on the look out to acquire London properties to build our extensive portfolio, while we also offer a number of properties for sale including individual units and bulk investments.
                        </p>
                    </Col>
                </Row>
                <Row className="property-types-tiles">
                    {
                        propertyTypes.map( ( propertyTypesItem, propertyTypesIndex ) => {
                            return (
                                <Col
                                    key={ propertyTypesIndex }
                                    lg="4"
                                    md="6"
                                    className={ ( propertyTypesIndex > 3 ) ? 'hide-on-tab-and-mobile' : ( propertyTypesIndex > 1 ) ? 'hide-on-mobile' : '' }
                                >
                                    <div className="property-type-tile">
                                        <div className="property-type-image">
                                            <img
                                                src={ require( `../../../images/property-types/${ propertyTypesItem.imageSource }` ).default }
                                                alt={ propertyTypesItem.name }
                                            />
                                        </div>
                                        <div className="property-type-content">
                                            <span>
                                                { propertyTypesItem.name }
                                            </span>
                                            <img
                                                src={ require( `../../../images/right-arrow-icon.png` ).default }
                                                alt="right-arrow"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            )
                        } )
                    }
                </Row>
            </Container>
        </SectionWrapper>
    )
}

export default PropertyTypes
