import React, { useRef } from "react"
import { Col, Container, Row } from "react-bootstrap";

// Styles
import './InteriorDesign.scss';

// Components
import SectionWrapper from "components/SectionWrapper";
import SectionHeader from "components/SectionHeader";
import CustomButton from "components/CustomButton";

const InteriorDesign = ( { interiorDesignData } ) => {

    const sectionHeaderData = {
        Title: 'Interior Design'
    };

    return (
        <SectionWrapper
            className="interior-design-wrapper"
            id="interiorDesign"
        >
            <Container>
                <Row>
                    <Col lg="6">
                        <div className="interior-design-image">
                            <img
                                src={ require( `../../../images/interior-design.png` ).default }
                                alt="Interior Design"
                            />
                        </div>
                    </Col>
                    <Col className="interior-design-content">
                        <div className="interior-design-header">
                            <SectionHeader
                                sectionHeaderData={ sectionHeaderData }
                            />
                        </div>
                        <div className="interior-design-body">
                            <p className="highlight-text">
                                Our professional interior design team has a wealth of experience and execute all aspects of our design projects, whether it is a small layout improvement or extensive refurbishment.
                            </p>
                            <p className="mb-0">
                                Having an in-house interior design team ensures that all our Resi.Land properties benefit from a truly personal touch and that our applicants or potential buyers are presented with a luxury residential home.
                            </p>
                            <div>
                                <CustomButton
                                    variant="outline"
                                    value="VIEW MORE"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </SectionWrapper>
    )
}

export default InteriorDesign
