import React from "react"
import { Container, Row, Col } from "react-bootstrap";
import HTMLReactParser from "html-react-parser"

// Components
import SectionWrapper from "components/SectionWrapper";
import CustomButton from "components/CustomButton";
import {ImageModule} from '../../../modules/Image_Module'

// Styles
import './DirectorProfile.scss';

const DirectorProfile = ( { details, ContactForm, myRef } ) => {

    let processedImages = JSON.stringify({});
    if (details?.imagetransforms?.image_Transforms) {
        processedImages = details?.imagetransforms?.image_Transforms;
    }

    return (
        <SectionWrapper className="director-profile-wrapper">
            <div className="director-image show-only-on-tab-and-mobile">
                <ImageModule ImageSrc={details.image} imagetransforms={processedImages} renderer="srcSet" imagename="teams.image.detail_image" strapi_id={details.id} />    
            </div>
            <Container>
                <Row>
                    <Col
                        lg="8"
                    >
                        <div className="director-profile-header">
                            <h1 className="general-heading">
                                {details.Title}
                            </h1>
                            {details.Designation && 
                                <p className="designation">
                                    {details.Designation}
                                </p>
                            }
                            
                            {details.Areas_of_Speciality && 
                            <p className="speciality-areas">
                                <b>Areas of Speciality:</b> {details.Areas_of_Speciality}
                            </p>
                            }

                            <div className="d-none d-sm-block">
                                <CustomButton
                                    variant="green"
                                    value={`CONTACT ${details.Title}`}
                                    onClick={ContactForm}
                                />
                            </div>

                            
                        </div>

                        {details.Content && 
                        <div className="director-profile-body">
                            {HTMLReactParser(details.Content)}
                        </div>
                        }

                        <div ref={myRef}></div> 
         
                    </Col>
                    <Col className="hide-on-tab-and-mobile">
                        <div className="director-image">
                            
                            <ImageModule ImageSrc={details.image} imagetransforms={processedImages} renderer="srcSet" imagename="teams.image.detail_image" strapi_id={details.id} />  
                        </div>
                    </Col>
                </Row>
                
            </Container>
        </SectionWrapper>
    )
}

export default DirectorProfile