import React, { useRef } from "react"
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";

// Configs
import multiContentSlider from "../../../utilities/multiContentSlider";

// Styles
import './FoodAndDrink.scss';

// LayoutHelpers
import Layout01 from "../../../layout-helpers/Layout01";

const FoodAndDrink = ( { foodAndDrinkData } ) => {

    const { Title, ...otherProperties } = foodAndDrinkData;

    const sectionHeaderData = {
        Title: Title
    };

    return (
        <Layout01
            className="food-and-drink-wrapper"
            id="foodanddrink"
            sectionHeaderData={ sectionHeaderData }
            cardType="contentCard"
            { ...otherProperties }
        />
    )
}

export default FoodAndDrink
